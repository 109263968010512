import slugify from 'slugify'
import { easing } from 'popmotion'

export const PAGE_TYPE_GENERAL = 'PAGE_TYPE_GENERAL'
export const PAGE_TYPE_CASE_STUDY = 'PAGE_TYPE_CASE_STUDY'
export const PAGE_TYPE_JOB = 'PAGE_TYPE_JOB'

export const THEME_WHITE = 'white'
export const THEME_TAUPE = 'taupe'
export const THEME_PEACH = 'peach'
export const THEME_CITRON = 'citron'
export const THEME_GREEN = 'green'
export const THEME_PERIWINKLE = 'periwinkle'

export const FOOTER_SLIDE_TRACKING_KEY = '---footerslide-key'

export const LOCATION_STATE_SCROLL_TOP = 'LOCATION_STATE_SCROLL_TOP'
export const LOCATION_STATE_SKIP_SCROLL_RESTORE =
	'LOCATION_STATE_SKIP_SCROLL_RESTORE'

export const media = {
	small: '@media (max-width: 767px)',
	medium: '@media (min-width: 768px) and (max-width: 1023px)',
	mediumUp: '@media (min-width: 768px)',
	large: '@media (min-width: 1024px)',
	extraLarge: '@media (min-width: 1400px)',
	giant: '@media (min-width: 1800px)',
	extraTall: '@media (min-height: 800px)',
}

export const typeThin = {
	fontFamily: "'PlainThin', Helvetica, sans-serif",
	fontWeight: 'normal',
	fontStyle: 'normal',
	textRendering: 'optimizeLegibility',
}

export const typeLight = {
	fontFamily: "'PlainLight', Helvetica, sans-serif",
	fontWeight: 'normal',
	fontStyle: 'normal',
	textRendering: 'optimizeLegibility',
}

export const typeMed = {
	fontFamily: "'PlainMedium', Helvetica, sans-serif",
	fontWeight: 'normal',
	fontStyle: 'normal',
	textRendering: 'optimizeLegibility',
}

export const colors = {
	white: '#FFFFFF',
	ink: '#282828',
	grey: '#7E7E7E',
	taupe: '#F5F3EB',
	citron: '#EDFFB3',
	green: '#CDFADC',
	peach: '#FEE4BF',
	periwinkle: '#DAE6F7',

	border: '#E6E6E6',
}

export const themeAccentColorMap = {
	[THEME_WHITE]: colors.ink,
	[THEME_TAUPE]: colors.ink,
	[THEME_PEACH]: '#FACB89',
	[THEME_CITRON]: '#DEEB8D',
	[THEME_GREEN]: '#91EBAF',
	[THEME_PERIWINKLE]: '#9BBAE6',
}

export const themeColorMap = {
	[THEME_WHITE]: colors.white,
	[THEME_TAUPE]: colors.taupe,
	[THEME_PEACH]: colors.peach,
	[THEME_CITRON]: colors.citron,
	[THEME_GREEN]: colors.green,
	[THEME_PERIWINKLE]: colors.periwinkle,
}

const removeRegex = /[*+~.()'"!:@]/g
export const convertToSlug = text => {
	return slugify(text, {
		lower: true,
		remove: removeRegex,
	})
}

export const typeRamp = {
	light_96: {
		fontSize: `${96 / 16}rem`,
		lineHeight: `${116 / 16}rem`,
		letterSpacing: '-2px',
		...typeLight,
	},

	light_80: {
		fontSize: `${80 / 16}rem`,
		lineHeight: `${86 / 16}rem`,
		letterSpacing: '-1px',
		...typeLight,
	},

	light_50: {
		fontSize: `${50 / 16}rem`,
		lineHeight: `${70 / 16}rem`,
		letterSpacing: '-0.2px',
		...typeLight,
	},
	light_40: {
		fontSize: `${40 / 16}rem`,
		lineHeight: `${48 / 16}rem`,
		letterSpacing: '-0.3px',
		...typeLight,
	},
	light_30: {
		fontSize: `${30 / 16}rem`,
		lineHeight: `${46 / 16}rem`,
		letterSpacing: '-0.1px',
		...typeLight,
	},
	med_20: {
		fontSize: `${20 / 16}rem`,
		lineHeight: `${30 / 16}rem`,
		letterSpacing: '-0.1px',
		...typeMed,
	},
	light_20: {
		fontSize: `${20 / 16}rem`,
		lineHeight: `${30 / 16}rem`,
		letterSpacing: '-0.1px',
		...typeLight,
	},
	med_16: {
		fontSize: `${16 / 16}rem`,
		lineHeight: `${24 / 16}rem`,
		letterSpacing: '0',
		...typeMed,
	},
	light_16: {
		fontSize: `${16 / 16}rem`,
		lineHeight: `${26 / 16}rem`,
		letterSpacing: '0',
		...typeLight,
	},
	med_14: {
		fontSize: `${14 / 16}rem`,
		lineHeight: `${18 / 16}rem`,
		letterSpacing: '0',
		...typeMed,
	},
}

export const rodoEase = easing.cubicBezier(0.77, 0.0, 0.175, 1.0)
export const rodoEaseValues = [0.77, 0.0, 0.175, 1.0]
export const rodoEaseCSS = 'cubic-bezier(0.770, 0.000, 0.175, 1.000)'
