import React, { createContext } from 'react'
import PropTypes from 'prop-types'

import { getDisplayName } from '../containers/utils'

export const SlideTrackerContextPropTypes = {
	activeSlideKey: PropTypes.string,
	intersectionObserver: PropTypes.object,
}

const SlideTrackerContext = createContext({
	activeSlideKey: null,
	intersectionObserver: null,
	registerSlide: ({ trackingKey, target }) => {},
	deregisterSlide: ({ trackingKey, target }) => {},
})

export default SlideTrackerContext

export const withSlideTracker = Component => {
	const C = props => {
		return (
			<SlideTrackerContext.Consumer>
				{themeProps => <Component {...props} {...themeProps} />}
			</SlideTrackerContext.Consumer>
		)
	}

	C.displayName = `withSlideTracker(${getDisplayName(Component)})`
	return C
}
