// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-academy-design-ethics-index-tsx": () => import("./../../../src/pages/academy/design-ethics/index.tsx" /* webpackChunkName: "component---src-pages-academy-design-ethics-index-tsx" */),
  "component---src-pages-academy-design-ethics-register-form-tsx": () => import("./../../../src/pages/academy/design-ethics/RegisterForm.tsx" /* webpackChunkName: "component---src-pages-academy-design-ethics-register-form-tsx" */),
  "component---src-pages-academy-design-ethics-watch-tsx": () => import("./../../../src/pages/academy/design-ethics/watch.tsx" /* webpackChunkName: "component---src-pages-academy-design-ethics-watch-tsx" */),
  "component---src-pages-approach-jsx": () => import("./../../../src/pages/approach.jsx" /* webpackChunkName: "component---src-pages-approach-jsx" */),
  "component---src-pages-company-jsx": () => import("./../../../src/pages/company.jsx" /* webpackChunkName: "component---src-pages-company-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-privacy-jsx": () => import("./../../../src/pages/privacy.jsx" /* webpackChunkName: "component---src-pages-privacy-jsx" */),
  "component---src-pages-students-tsx": () => import("./../../../src/pages/students.tsx" /* webpackChunkName: "component---src-pages-students-tsx" */),
  "component---src-pages-terms-jsx": () => import("./../../../src/pages/terms.jsx" /* webpackChunkName: "component---src-pages-terms-jsx" */),
  "component---src-pages-work-cloudkitchens-tsx": () => import("./../../../src/pages/work/cloudkitchens.tsx" /* webpackChunkName: "component---src-pages-work-cloudkitchens-tsx" */),
  "component---src-pages-work-coinbase-tsx": () => import("./../../../src/pages/work/coinbase.tsx" /* webpackChunkName: "component---src-pages-work-coinbase-tsx" */),
  "component---src-pages-work-credit-karma-tsx": () => import("./../../../src/pages/work/credit-karma.tsx" /* webpackChunkName: "component---src-pages-work-credit-karma-tsx" */),
  "component---src-pages-work-ebay-jsx": () => import("./../../../src/pages/work/ebay.jsx" /* webpackChunkName: "component---src-pages-work-ebay-jsx" */),
  "component---src-pages-work-facebook-tsx": () => import("./../../../src/pages/work/facebook.tsx" /* webpackChunkName: "component---src-pages-work-facebook-tsx" */),
  "component---src-pages-work-google-jsx": () => import("./../../../src/pages/work/google.jsx" /* webpackChunkName: "component---src-pages-work-google-jsx" */),
  "component---src-pages-work-index-jsx": () => import("./../../../src/pages/work/index.jsx" /* webpackChunkName: "component---src-pages-work-index-jsx" */),
  "component---src-pages-work-tory-burch-jsx": () => import("./../../../src/pages/work/tory-burch.jsx" /* webpackChunkName: "component---src-pages-work-tory-burch-jsx" */),
  "component---src-pages-work-toyota-research-institute-tsx": () => import("./../../../src/pages/work/toyota-research-institute.tsx" /* webpackChunkName: "component---src-pages-work-toyota-research-institute-tsx" */),
  "component---src-pages-work-toyota-tsx": () => import("./../../../src/pages/work/toyota.tsx" /* webpackChunkName: "component---src-pages-work-toyota-tsx" */),
  "component---src-pages-work-unqork-jsx": () => import("./../../../src/pages/work/unqork.jsx" /* webpackChunkName: "component---src-pages-work-unqork-jsx" */),
  "component---src-pages-work-waze-tsx": () => import("./../../../src/pages/work/waze.tsx" /* webpackChunkName: "component---src-pages-work-waze-tsx" */),
  "component---src-pages-work-workrise-tsx": () => import("./../../../src/pages/work/workrise.tsx" /* webpackChunkName: "component---src-pages-work-workrise-tsx" */)
}

