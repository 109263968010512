import { actionTypes as snackbarActions } from './reducers/snackbar'

// SNACK BAR /////////////

export const showSnack = (id, data = { label: '' }) => {
	return {
		type: snackbarActions.SHOW_SNACK,
		payload: { id, data },
	}
}

export const dismissSnack = id => {
	return {
		type: snackbarActions.DISMISS_SNACK,
		payload: { id },
	}
}

// MEDIA /////////////////////

export const MEDIA_INSTANCE_PLAY = 'MEDIA_INSTANCE_PLAY'
const mediaInstancePlay = mediaInstanceID => ({
	type: MEDIA_INSTANCE_PLAY,
	mediaInstanceID,
})

export const mediaInstancePlayed = mediaInstanceID => dispatch => {
	dispatch(mediaInstancePlay(mediaInstanceID))
}
