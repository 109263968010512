import React, { createContext } from 'react'
import PropTypes from 'prop-types'

import {
	THEME_WHITE,
	THEME_TAUPE,
	THEME_PEACH,
	THEME_CITRON,
	THEME_GREEN,
	THEME_PERIWINKLE,
} from '../constants'
import { getDisplayName } from '../containers/utils'

export const themePropType = PropTypes.oneOf([
	THEME_WHITE,
	THEME_TAUPE,
	THEME_PEACH,
	THEME_CITRON,
	THEME_GREEN,
	THEME_PERIWINKLE,
])

export const UIContextPropTypes = {
	theme: themePropType.isRequired,
	setTheme: PropTypes.func.isRequired,

	menuOpen: PropTypes.bool.isRequired,

	onEnterFooterNav: PropTypes.func.isRequired,
	onExitFooterNav: PropTypes.func.isRequired,
}

const ThemeContext = createContext({
	theme: THEME_WHITE,
	setTheme: () => {},

	menuOpen: false,
	toggleMenuOpen: () => {},

	onEnterFooterNav: () => {},
	onExitFooterNav: () => {},
})

export default ThemeContext

export const withTheme = Component => {
	const C = props => {
		return (
			<ThemeContext.Consumer>
				{themeProps => <Component {...props} {...themeProps} />}
			</ThemeContext.Consumer>
		)
	}

	C.displayName = `WithTheme(${getDisplayName(Component)})`
	return C
}
