/** @jsx jsx */
import { jsx } from '@emotion/core'

export const GRID_GUTTER_WIDTH = 100.0 / 23.0

const Row = ({ children, style, ...props }) => (
	<div
		data-comp="row"
		css={[
			{
				display: 'flex',
				flexWrap: 'wrap',
				marginLeft: `-${GRID_GUTTER_WIDTH / 2}%`,
				marginRight: `-${GRID_GUTTER_WIDTH / 2}%`,
			},
			style,
		]}
		{...props}
	>
		{children}
	</div>
)

const cellBaseStyles = {
	flex: '0 0',
	padding: `0 ${GRID_GUTTER_WIDTH / 2}%`,
	boxSizing: 'border-box',
}

const columnStyleMap = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].reduce(
	(map, col) => {
		return {
			...map,
			[`col${col}`]: {
				flex: '0 0',
				width: `${(col / 12) * 100}%`,
				flexBasis: `${(col / 12) * 100}%`,
			},
			[`col${col}_half`]: {
				flex: '0 0',
				width: `${((col + 0.5) / 12) * 100}%`,
				flexBasis: `${((col + 0.5) / 12) * 100}%`,
			},
		}
	},
	{}
)

const offsetStyleMap = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].reduce(
	(map, col) => {
		return {
			...map,
			[`offset${col}`]: {
				marginLeft: `${(col / 12) * 100}%`,
			},
			[`offset${col}_half`]: {
				marginLeft: `${((col + 0.5) / 12) * 100}%`,
			},
		}
	},
	{}
)

export const buildCellStyle = ({ cols, offset, extraStyle }) => {
	return [
		cellBaseStyles,

		!cols
			? columnStyleMap.col12
			: typeof cols === 'number' || typeof cols === 'string'
			? columnStyleMap[`col${cols}`] || columnStyleMap.col12
			: Object.keys(cols).reduce((map, k) => {
					return {
						...map,
						[k]: columnStyleMap[`col${cols[k]}`],
					}
			  }, {}),

		!offset
			? null
			: typeof cols === 'number' || typeof cols === 'string'
			? offsetStyleMap[`offset${offset}`] || null
			: Object.keys(offset).reduce((map, k) => {
					return {
						...map,
						[k]: offsetStyleMap[`offset${offset[k]}`],
					}
			  }, {}),

		extraStyle,
	]
}

const Cell = ({ tag: Tag, children, cols, offset, style }) => {
	const offsetStyle = !offset
		? null
		: typeof offset === 'number'
		? offsetStyleMap[`offset${offset}`] || null
		: Object.keys(offset).reduce((map, k) => {
				return {
					...map,
					[k]: offsetStyleMap[`offset${offset[k]}`],
				}
		  }, {})

	const columnStyle = [
		!cols
			? columnStyleMap.col12
			: typeof cols === 'number'
			? columnStyleMap[`col${cols}`] || columnStyleMap.col12
			: Object.keys(cols).reduce((map, k) => {
					return {
						...map,
						[k]: columnStyleMap[`col${cols[k]}`],
					}
			  }, {}),

		offsetStyle,

		style,
	]

	return (
		<Tag data-comp="cell" css={[cellBaseStyles, columnStyle]}>
			{children}
		</Tag>
	)
}

Cell.defaultProps = {
	tag: 'div',
	cols: 12,
	offset: {},
}

const GridOverlay = () => {
	const style = {
		height: '100%',
		'&:after': {
			content: '" "',
			height: '100%',
			width: '100%',
			display: 'block',
			background: 'rgba(73, 222, 227, 0.1)',
		},
	}
	return (
		<Row
			style={{
				height: '100%',
			}}
		>
			<Cell cols={1} style={style} />
			<Cell cols={1} style={style} />
			<Cell cols={1} style={style} />
			<Cell cols={1} style={style} />
			<Cell cols={1} style={style} />
			<Cell cols={1} style={style} />
			<Cell cols={1} style={style} />
			<Cell cols={1} style={style} />
			<Cell cols={1} style={style} />
			<Cell cols={1} style={style} />
			<Cell cols={1} style={style} />
			<Cell cols={1} style={style} />
		</Row>
	)
}

export { Row, Cell, GridOverlay }
