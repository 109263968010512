export const wrapRootElement = require('./src/wrapRootElement').Client

export const wrapPageElement = require('./src/wrapPageElement').default

export const shouldUpdateScroll = props => {
	try {
		// If we're clicking the back button
		// and the page we navigated away from was at the footer/menu
		// don't restore scroll, just go to the top
		if (
			props.prevRouterProps.location.state.LOCATION_STATE_SKIP_SCROLL_RESTORE
		) {
			if (props.prevRouterProps.location.action === 'PUSH') {
				window.scrollTo(0, 0)
				return false
			}
		}
	} catch (e) {}

	return true
}
