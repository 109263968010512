// A mechanism to force rehydrate certain components that look one way in SSR, but show in a different way in the client. Typically relates to JS driven media queries.

import React, { createContext } from 'react'
import { getDisplayName } from '../containers/utils'

export const RUNTIME_CLIENT = 'client'
export const RUNTIME_SSR = 'ssr'

const RuntimeContext = createContext({
	runtimeContext: RUNTIME_CLIENT,
})

export default RuntimeContext

export const withRuntimeContext = Component => {
	const C = props => {
		return (
			<RuntimeContext.Consumer>
				{ssrProps => <Component {...props} {...ssrProps} />}
			</RuntimeContext.Consumer>
		)
	}

	C.displayName = `withRuntimeContext(${getDisplayName(Component)})`
	return C
}
