/** @jsx jsx */
import { FC } from 'react'
import { jsx, keyframes } from '@emotion/core'
import { GatsbyLinkProps, Link as GatsbyLink } from 'gatsby'
import { Location, LocationContext } from '@reach/router'

import { typeMed, colors, typeLight } from '../../constants'

type RouterLink = FC<
	Omit<GatsbyLinkProps<any>, 'ref'> & {
		style?: any
	}
>

const chase = keyframes({
	'0%': { transform: 'scaleX(0)' },
	'100%': { transform: 'scaleX(1)' },
})

export const buildInlineLinkStyle = ({ hoverSelector = '&:hover' } = {}) => {
	return {
		...typeMed,
		display: 'inline-block', // this helps with underline placement
		position: 'relative',
		border: 'none',
		cursor: 'pointer',
		color: colors.ink,

		'&::after': {
			content: '" "',
			position: 'absolute',
			left: 0,
			bottom: 0,
			height: 1,
			width: '100%',
			background: colors.ink,
			transformOrigin: 'left top',
			transform: 'scaleX(1)',
		},

		[hoverSelector]: {
			color: colors.ink,
			'&::after': {
				animation: `${chase} 750ms cubic-bezier(0.19, 1, 0.22, 1)`,
			},
		},
	}
}

export const InlineLink: RouterLink = ({ children, style, ...props }) => {
	return (
		<RouterLink {...props} css={[buildInlineLinkStyle(), style]}>
			{children}
		</RouterLink>
	)
}

export const buildStandaloneLinkStyle = ({
	hoverSelector = '&:hover',
} = {}) => {
	return {
		...typeLight,
		display: 'inline-block', // this helps with underline placement
		position: 'relative',
		border: 'none',
		cursor: 'pointer',
		color: colors.ink,
		textDecoration: 'none',
		background: 'none',
		transition: 'color 150ms linear',

		'&::after': {
			content: '" "',
			position: 'absolute',
			left: 0,
			bottom: 0,
			height: 1,
			width: '100%',
			background: colors.ink,
			transformOrigin: 'left top',
			transform: 'scaleX(0)',
			transition: 'transform 400ms cubic-bezier(0.19, 1, 0.22, 1)',
		},

		[hoverSelector]: {
			color: colors.ink,
			'&::after': {
				transform: 'scaleX(1)',
			},
		},
	}
}

export const StandaloneLink: typeof RouterLink = ({
	children,
	style,
	...props
}) => {
	return (
		<RouterLink {...props} css={[buildStandaloneLinkStyle(), style]}>
			{children}
		</RouterLink>
	)
}

export const RouterLink: RouterLink = ({
	to,
	style,
	children,
	replace,
	...rest
}) => {
	return (
		<Location>
			{({ location }: LocationContext) => {
				let resolvedReplace = replace
				let external = false

				try {
					const toURL = new URL(to, location.origin)
					// Always replace if the navigation is to the current location
					// Otherwise we get some funky back button business
					resolvedReplace = location.href === toURL.href

					if (toURL.origin !== location.origin) {
						external = true
					}
				} catch (e) {
					// Turn me on to debug external link issues
					// console.error(e)
				}

				if (!external) {
					return (
						<GatsbyLink to={to} {...rest} replace={resolvedReplace}>
							{children}
						</GatsbyLink>
					)
				}

				return (
					<a href={to} {...rest}>
						{children}
					</a>
				)
			}}
		</Location>
	)
}
