export const actionTypes = {
	SHOW_SNACK: 'SHOW_SNACK',
	DISMISS_SNACK: 'DISMISS_SNACK',
}

const initialState = {
	queue: [],
}

export default (state = initialState, { type, payload } = {}) => {
	switch (type) {
		case actionTypes.SHOW_SNACK:
			const { queue } = state
			const existingIDs = queue.reduce((map, snack) => {
				return {
					...map,
					[snack.id]: true,
				}
			}, {})
			// Don't double snack the ID
			if (existingIDs[payload.id]) {
				return state
			}
			return {
				queue: [...queue, { id: payload.id, label: payload.data.label }],
			}
		case actionTypes.DISMISS_SNACK:
			return {
				queue: state.queue.filter(snack => {
					return payload.id && snack.id !== payload.id
				}),
			}
		default:
			return state
	}
}
