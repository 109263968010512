import React from 'react'
import { Location } from '@reach/router'
import { getDisplayName } from './utils'

export default WrappedComponent => {
	const C = props => (
		<Location>
			{({ location }) => <WrappedComponent {...props} location={location} />}
		</Location>
	)
	C.displayName = `WithLocation(${getDisplayName(WrappedComponent)})`
	return C
}
