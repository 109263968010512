/** @jsx jsx */
import { jsx } from '@emotion/core'
import PropTypes from 'prop-types'
import Clipboard from 'clipboard'

import { buildInlineLinkStyle } from './content/Link'
import withSnackActions from '../containers/withSnacks'
import { typeRamp } from '../constants'
import { Component, createRef } from 'react'

const propTypes = {
	showSnack: PropTypes.func,
	copiedMessage: PropTypes.string,
	copyValue: PropTypes.string,
}

const defaultProps = {
	showSnack: () => {},
	copiedMessage: 'Copied to clipboard!',
	copyValue: '',
}

class CopyButton extends Component {
	constructor(props) {
		super(props)
		this.buttonRef = createRef()
		this.clipboardInstance = null
	}

	componentDidMount() {
		this.clipboardInstance = new Clipboard(this.buttonRef.current)
		this.clipboardInstance.on('success', () => {
			this.showCopySnack()
		})
	}

	componentWillUnmount() {
		this.clipboardInstance.destroy()
	}

	showCopySnack = () => {
		this.props.showSnack('copybutton', {
			label: this.props.copiedMessage,
		})
	}

	render() {
		const { ariaLabel, copyValue, children, className } = this.props
		return (
			<button
				ref={this.buttonRef}
				aria-label={ariaLabel}
				data-clipboard-text={copyValue}
				className={className}
			>
				{children}
			</button>
		)
	}
}

CopyButton.propTypes = propTypes
CopyButton.defaultProps = defaultProps

export default withSnackActions(CopyButton)

export const CopyButtonInline = ({ children, ...props }) => {
	const B = withSnackActions(CopyButton)
	return (
		<B
			css={[
				buildInlineLinkStyle(),
				{
					...typeRamp.med_16,
					border: 'none',
					cursor: 'pointer',
				},
			]}
			{...props}
		>
			{children}
		</B>
	)
}
