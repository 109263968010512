/** @jsx jsx */
import PropTypes from 'prop-types'
import { jsx } from '@emotion/core'
import uuid from 'uuid/v4'

import { THEME_WHITE, themeColorMap, media } from '../constants'
import { themePropType } from '../context/ThemeContext'
import {
	withSlideTracker,
	SlideTrackerContextPropTypes,
} from '../context/SlideTrackerContext'
import { Component } from 'react'

export const SLIDE_LAYOUT_DEFAULT = 'SLIDE_LAYOUT_DEFAULT'
export const SLIDE_LAYOUT_FULL_SCREEN = 'SLIDE_LAYOUT_FULL_SCREEN'
export const SLIDE_LAYOUT_MOCK_FULL_SCREEN = 'SLIDE_LAYOUT_MOCK_FULL_SCREEN'
export const SLIDE_LAYOUT_FULL_BLEED_IMAGE = 'SLIDE_LAYOUT_FULL_BLEED_IMAGE'

const propTypes = {
	trackingKey: PropTypes.string,
	...SlideTrackerContextPropTypes,
	prefferedTheme: themePropType,
	layout: PropTypes.oneOf([
		SLIDE_LAYOUT_DEFAULT,
		SLIDE_LAYOUT_FULL_SCREEN,
		SLIDE_LAYOUT_MOCK_FULL_SCREEN,
		SLIDE_LAYOUT_FULL_BLEED_IMAGE,
	]),
}

const defaultProps = {
	trackingKey: null,
	prefferedTheme: THEME_WHITE,
	layout: SLIDE_LAYOUT_DEFAULT,
	intersectionObserver: null,
	activeSlideKey: null,
}

const baseLayoutStyle = {
	width: '100vw',
	position: 'relative',
	display: 'flex',
	alignItems: 'center',
	paddingTop: '8rem',
	paddingBottom: '8rem',
	transition: 'opacity 300ms linear',

	[media.small]: {
		minHeight: '45vh',
		paddingTop: '5rem',
		paddingBottom: '5rem',
	},
}

const layoutStyles = {
	[SLIDE_LAYOUT_DEFAULT]: baseLayoutStyle,
	[SLIDE_LAYOUT_MOCK_FULL_SCREEN]: [
		baseLayoutStyle,
		{
			// Fake vertical centering by messing with the padding
			// and translation! Very trixy. The point is to make
			// it feel like a full viewport slide, but take the normal
			// amount of scroll travel to encounter the next slide. #per-the-designers-obviously
			[media.small]: {
				paddingTop: '12rem',
				paddingBottom: '0rem',
			},
			[media.mediumUp]: {
				minHeight: '70vh',
				paddingTop: 'calc(100vh - 70vh - 9rem)',
				paddingBottom: '3rem',
				'& [data-comp="slide-children"]': {
					transform: 'translateY(4.5rem)',
				},
			},
		},
	],
	[SLIDE_LAYOUT_FULL_SCREEN]: [
		baseLayoutStyle,
		{
			minHeight: '100vh',
			[media.small]: {
				minHeight: '0',
			},
		},
	],
	[SLIDE_LAYOUT_FULL_BLEED_IMAGE]: [
		baseLayoutStyle,
		{
			minHeight: '50vh',
			paddingTop: '0',
			paddingBottom: '0',

			[media.small]: {
				minHeight: '0',
				paddingTop: '0',
				paddingBottom: '0',
			},
		},
	],
}

class Slide extends Component {
	constructor(props) {
		super(props)
		this.state = {}
		this.slideRef = null
	}

	static getDerivedStateFromProps({ trackingKey }, prevState) {
		if (trackingKey) {
			return {
				...prevState,
				trackingKey,
			}
		}

		if (!prevState.trackingKey) {
			return {
				...prevState,
				// If no trackingKey prop comes in, assign one randomly
				// this is what the
				trackingKey: trackingKey || uuid(),
			}
		}

		return prevState
	}

	componentDidMount() {
		const { registerSlide } = this.props

		registerSlide({
			trackingKey: this.state.trackingKey,
			target: this.slideRef,
			prefferedTheme: this.props.prefferedTheme,
		})
	}

	setSlideRef = ref => {
		if (!ref) {
			this.slideRef = null
			return
		}

		const { trackingKey } = this.state

		// In an SSR context, the server build probably assigned a different random
		// trackingKey than what get's assigned at runtime. This will throw off the SlideTracker
		// since it will look at the ref's data-slide-key attribute before the Slide has a chance
		// to hydrate with correct tracking key.
		if (ref.getAttribute('data-slide-key') !== trackingKey) {
			ref.setAttribute('data-slide-key', this.state.trackingKey)
		}

		this.slideRef = ref
	}

	render() {
		const {
			children,
			prefferedTheme,
			activeSlideKey,
			style,
			layout,
		} = this.props
		const isActive = activeSlideKey.trim() === this.state.trackingKey.trim()

		return (
			<div
				ref={this.setSlideRef}
				data-slide-key={this.state.trackingKey}
				data-slide-theme={prefferedTheme}
				data-slide-layout={layout}
				css={[
					layoutStyles[layout],
					{
						[media.small]: {
							background: themeColorMap[prefferedTheme],
						},
						[media.mediumUp]: {
							opacity: isActive ? 1 : 0,
						},
					},
					style,
				]}
			>
				<div
					data-comp="slide-children"
					css={{
						width: '100%',
						position: 'relative',
					}}
				>
					{children}
				</div>
			</div>
		)
	}
}

Slide.propTypes = propTypes
Slide.defaultProps = defaultProps
Slide.displayName = 'Slide'

export default withSlideTracker(Slide)
