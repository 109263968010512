import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'

import reducers from './store/reducers'
import RuntimeContext, {
	RUNTIME_SSR,
	RUNTIME_CLIENT,
} from './context/RuntimeContext'

const store = createStore(reducers, {}, applyMiddleware(thunkMiddleware))

const propTypes = {
	children: PropTypes.node.isRequired,
}

const defaultProps = {
	children: [],
}

class RootElement extends Component {
	constructor(props) {
		super(props)
		this.state = {
			runtimeContext: RUNTIME_SSR,
		}
	}

	componentDidMount() {
		if (this.state.runtimeContext !== RUNTIME_CLIENT) {
			this.setState({
				runtimeContext: RUNTIME_CLIENT,
			})
		}
	}

	render() {
		const { children } = this.props
		const { runtimeContext } = this.state
		return (
			<RuntimeContext.Provider
				value={{
					runtimeContext,
				}}
			>
				<Provider store={store}>{children}</Provider>
			</RuntimeContext.Provider>
		)
	}
}

RootElement.propTypes = propTypes
RootElement.defaultProps = defaultProps

export const Client = ({ element, props }) => {
	// props provide same data to Layout as Page element will get
	// including location, data, etc - you don't need to pass it
	return (
		<RootElement {...props} ssrProp={RUNTIME_SSR}>
			{element}
		</RootElement>
	)
}

export const SSR = ({ element, props }) => {
	// props provide same data to Layout as Page element will get
	// including location, data, etc - you don't need to pass it
	return (
		<RootElement {...props} ssrProp={RUNTIME_SSR}>
			{element}
		</RootElement>
	)
}
