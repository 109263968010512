/** @jsx jsx */
import PropTypes from 'prop-types'
import { jsx } from '@emotion/core'

import { InlineLink, StandaloneLink } from './Link'
import { typeRamp, media, colors } from '../../constants'

const propTypes = {
	title: PropTypes.string,
	links: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string.isRequired,
			href: PropTypes.string.isRequired,
			description: PropTypes.string,
			state: PropTypes.object, // used in history.pushState
		})
	),
	footerLink: PropTypes.shape({
		title: PropTypes.string.isRequired,
		href: PropTypes.string.isRequired,
	}),
}

/* eslint-disable no-unused-vars */
const defaultProps = {
	links: [],
}
/* eslint-enable no-unused-vars */

const LinkPile = ({ links, title, footerLink }) => {
	const linksHaveDescriptions = links.filter(l => !!l.description).length > 0

	return (
		<div>
			{title && (
				<h4
					css={{
						color: colors.grey,
						...typeRamp.light_16,
						marginBottom: linksHaveDescriptions ? '3rem' : '2rem',

						[media.mediumUp]: {
							...typeRamp.light_20,
							marginBottom: linksHaveDescriptions ? '5rem' : '2rem',
						},
					}}
				>
					{title}
				</h4>
			)}
			<ul
				css={{
					'& > * + *': {
						marginTop: linksHaveDescriptions ? '3rem' : '0.25rem',
						[media.mediumUp]: {
							marginTop: linksHaveDescriptions ? '5rem' : '0.5rem',
						},
					},
				}}
			>
				{links.map(({ title, href, description, state, style }, idx) => {
					return (
						<li key={idx}>
							<div
								css={{
									...typeRamp.light_30,
									[media.mediumUp]: {
										...typeRamp.light_40,
									},
									[media.extraLarge]: {
										...typeRamp.light_50,
									},
								}}
							>
								<StandaloneLink to={href} state={state} style={style}>
									{title}
								</StandaloneLink>
							</div>
							{description && (
								<div
									css={{
										...typeRamp.light_16,
										color: colors.grey,
										[media.mediumUp]: {
											...typeRamp.light_20,
											paddingTop: '0.5rem',
										},
									}}
								>
									{description}
								</div>
							)}
						</li>
					)
				})}
			</ul>
			{footerLink && (
				<div
					css={{
						...typeRamp.light_16,
						paddingTop: linksHaveDescriptions ? '3rem' : '2rem',
						[media.mediumUp]: {
							...typeRamp.light_20,
							paddingTop: linksHaveDescriptions ? '5rem' : '3rem',
						},
					}}
				>
					<InlineLink to={footerLink.href}>{footerLink.title}</InlineLink>
				</div>
			)}
		</div>
	)
}

LinkPile.propTypes = propTypes
LinkPile.defaultProps = defaultProps

export default LinkPile
