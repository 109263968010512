import React, { Component, createRef } from 'react'
import PropTypes from 'prop-types'
import 'what-input'
import 'intersection-observer'
import { motion, AnimatePresence } from 'framer-motion'
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock'

import {
	media,
	rodoEaseValues,
	THEME_WHITE,
	THEME_TAUPE,
	THEME_PEACH,
	THEME_CITRON,
	THEME_GREEN,
	THEME_PERIWINKLE,
	FOOTER_SLIDE_TRACKING_KEY,
} from './constants'
import ThemeContext, { withTheme } from './context/ThemeContext'
import Snackbar from './components/Snackbar'
import Header from './components/Header'
import Menu from './components/Menu'
import Footer from './components/Footer'
import SlideTracker from './components/SlideTracker'
import Slide, { SLIDE_LAYOUT_FULL_SCREEN } from './components/Slide'

import './fonts/PlainLight-Regular.css'
import './fonts/PlainMedium-Regular.css'
import './fonts/PlainThin-Regular.css'
import './main.scss'
import SiderailTitle from './components/SiderailTitle'
import BackgroundShapes from './components/BackgroundShapes'
import { GHSourceProvider } from './context/GHSourceContext'

const MENU_TRANSITION_PRESENT = 'MENU_TRANSITION_PRESENT'
const MENU_TRANSITION_DISMISS = 'MENU_TRANSITION_SHOW'
const MENU_TRANSITION_DISMISS_FAST = 'MENU_TRANSITION_DISMISS_FAST'

const propTypes = {
	children: PropTypes.node.isRequired,
}

const defaultProps = {
	children: [],
}

const navTransitionVariants = {
	[MENU_TRANSITION_DISMISS]: {
		opacity: 0,
		translateY: '60vh',
		transition: {
			translateY: {
				ease: rodoEaseValues,
				duration: 0.57,
			},
			opacity: {
				ease: 'linear',
				duration: 0.285,
			},
		},
	},
	[MENU_TRANSITION_PRESENT]: {
		opacity: 1,
		translateY: '0vh',
		transition: {
			translateY: {
				ease: rodoEaseValues,
				duration: 0.57,
			},
			opacity: {
				ease: 'linear',
				duration: 0.38,
			},
		},
	},
	[MENU_TRANSITION_DISMISS_FAST]: {
		opacity: 0,
		translateY: '60vh',
		transition: {
			translateY: {
				delay: 0.2,
				ease: 'linear',
				duration: 0,
			},
			opacity: {
				ease: 'linear',
				duration: 0.19,
			},
		},
	},
}

const contentTransitionVariants = {
	// Content enter
	[MENU_TRANSITION_DISMISS]: {
		opacity: 1,
		translateY: '0vh',
		transition: {
			translateY: {
				ease: rodoEaseValues,
				duration: 0.57,
			},
			opacity: {
				ease: 'linear',
				duration: 0.38,
			},
		},
	},
	// Content dismiss
	[MENU_TRANSITION_PRESENT]: {
		opacity: 0,
		translateY: '-60vh',
		transition: {
			translateY: {
				ease: rodoEaseValues,
				duration: 0.475,
			},
			opacity: {
				ease: 'linear',
				duration: 0.285,
			},
		},
	},
	// Content enter fast menu
	[MENU_TRANSITION_DISMISS_FAST]: {
		opacity: 1,
		translateY: '0vh',
		transition: {
			translateY: {
				ease: 'linear',
				duration: 0,
			},
			opacity: {
				delay: 0.5,
				ease: 'linear',
				duration: 0.475,
			},
		},
	},
}

const pageTransitionVariants = {
	enter: {
		opacity: 0,
		transition: {
			duration: 0,
		},
	},
	visible: {
		opacity: 1,
		transition: {
			delay: 0.5,
			duration: 2,
		},
	},
	exit: {
		opacity: 0,
		transition: {
			duration: 0.1,
		},
	},
}

class PageElement extends Component {
	constructor(props) {
		super(props)
		this.state = {
			theme: THEME_WHITE,
			menuTransitionState: MENU_TRANSITION_DISMISS,
			footerNavInView: false,
		}

		this.navRef = createRef()
		this.contentInnerRef = createRef()
	}

	componentDidMount() {
		const { theme } = this.state

		// Set initial theme
		document.body.classList.toggle('theme-white', theme === THEME_WHITE)
		document.body.classList.toggle('theme-taupe', theme === THEME_TAUPE)
		document.body.classList.toggle('theme-peach', theme === THEME_PEACH)
		document.body.classList.toggle('theme-citron', theme === THEME_CITRON)
		document.body.classList.toggle('theme-green', theme === THEME_GREEN)
		document.body.classList.toggle(
			'theme-periwinkle',
			theme === THEME_PERIWINKLE
		)
	}

	static getDerivedStateFromProps(props, prevState) {
		const {
			// from Reach Router
			location,
		} = props

		const { location: prevLocation } = prevState

		let menuTransitionState = prevState.menuTransitionState

		// Are we trying to transition to a new route?
		if (prevLocation && prevLocation.pathname !== location.pathname) {
			menuTransitionState = MENU_TRANSITION_DISMISS_FAST // close the menu if it's open
		}

		// Close the menu if location key (but not path) has changed
		// Possible if you click on a link for the current path
		if (
			location &&
			prevLocation &&
			location.pathname === prevLocation.pathname &&
			location.key !== prevLocation.key
		) {
			menuTransitionState = MENU_TRANSITION_DISMISS
		}

		return {
			...prevState,
			location, // store current location for future comparison
			menuTransitionState,
		}
	}

	componentDidUpdate(_, prevState) {
		const { theme, menuTransitionState } = this.state
		if (prevState.theme !== theme) {
			window.requestAnimationFrame(() => {
				document.body.classList.toggle('theme-white', theme === THEME_WHITE)
				document.body.classList.toggle('theme-taupe', theme === THEME_TAUPE)
				document.body.classList.toggle('theme-peach', theme === THEME_PEACH)
				document.body.classList.toggle('theme-citron', theme === THEME_CITRON)
				document.body.classList.toggle('theme-green', theme === THEME_GREEN)
				document.body.classList.toggle(
					'theme-periwinkle',
					theme === THEME_PERIWINKLE
				)
			})
		}

		if (menuTransitionState === MENU_TRANSITION_PRESENT) {
			// Lock the body from scrolling when the menu is open
			disableBodyScroll(this.navRef.current, {})
			document.body.classList.toggle('body-no-scroll', true)
			document.body.classList.toggle('theme-menu-open', true)
		} else {
			clearAllBodyScrollLocks()
			document.body.classList.toggle('body-no-scroll', false)
			document.body.classList.toggle('theme-menu-open', false)
		}
	}

	handleRequestTheme = theme => {
		this.setState({
			theme,
		})
	}

	handleToggleMenu = () => {
		this.setState(({ menuTransitionState }) => {
			return {
				menuTransitionState:
					menuTransitionState === MENU_TRANSITION_PRESENT
						? MENU_TRANSITION_DISMISS
						: MENU_TRANSITION_PRESENT,
			}
		})
	}

	handleEnterFooterNav = () => {
		this.setState({
			footerNavInView: true,
		})
	}

	handleExitFooterNav = () => {
		this.setState({
			footerNavInView: false,
		})
	}

	render() {
		const { children, location } = this.props
		const { theme, footerNavInView, menuTransitionState } = this.state

		const menuOpen = menuTransitionState === MENU_TRANSITION_PRESENT

		// if (location.pathname === '/academy/design-ethics/watch') {
		// 	return (
		// 		<GHSourceProvider location={location}>
		// 			{children}
		// 		</GHSourceProvider>
		// 	)
		// }

		return (
			<GHSourceProvider location={location}>
				<ThemeContext.Provider
					value={{
						// Theme should always be taupe if menu open
						theme: menuOpen ? THEME_TAUPE : theme,
						setTheme: this.handleRequestTheme,

						menuOpen,
						toggleMenuOpen: this.handleToggleMenu,

						onEnterFooterNav: this.handleEnterFooterNav,
						onExitFooterNav: this.handleExitFooterNav,
					}}
				>
					<motion.div
						initial={{
							opacity: 0,
						}}
						animate={{
							opacity: 1,
						}}
						transition={{
							ease: 'linear',
							delay: 0.5,
							duration: 0.5,
						}}
					>
						<div
							css={{
								opacity:
									theme === THEME_TAUPE || theme === THEME_WHITE || menuOpen
										? 0
										: 1,
								transition: 'opacity 300ms linear',
								position: 'fixed',
								top: 0,
								left: 0,
								width: '100vw',
								height: '100vh',
								overflow: 'hidden',

								[media.small]: {
									display: 'none',
								},
							}}
						>
							<BackgroundShapes />
						</div>

						<Header
							menuOpen={menuOpen}
							showWordmark={footerNavInView || menuOpen}
							onToggleMenu={this.handleToggleMenu}
							showMenuButton={!footerNavInView}
						/>
						<motion.div
							ref={this.navRef}
							initial={MENU_TRANSITION_DISMISS}
							animate={menuTransitionState}
							variants={navTransitionVariants}
							css={{
								position: 'fixed',
								top: 0,
								left: 0,
								height: '100vh',
								width: '100vw',
								zIndex: 9000,
								overflowY: 'scroll',
								pointerEvents: menuOpen ? 'auto' : 'none',
							}}
						>
							<Nav isHeader={true} />
						</motion.div>
						<div
							css={{
								// Hide the siderail is menu or footer
								// nav is visible
								opacity: menuOpen || footerNavInView ? 0 : 1,
								transition: 'opacity 400ms linear',
							}}
						>
							<AnimatePresence initial={false}>
								<motion.div
									// Animate the siderail on page
									// change in the same way as the main
									// content
									key={location.key}
									variants={pageTransitionVariants}
									initial="enter"
									animate="visible"
									exit="exit"
								>
									<SiderailTitle location={location} />
								</motion.div>
							</AnimatePresence>
						</div>
						<motion.div
							initial={MENU_TRANSITION_DISMISS}
							animate={menuTransitionState}
							variants={contentTransitionVariants}
							css={{
								zIndex: 1000,
								width: '100vw',
								pointerEvents: menuOpen ? 'none' : 'default',
							}}
						>
							<div ref={this.contentInnerRef}>
								<AnimatePresence initial={false}>
									<motion.div
										key={location.key}
										variants={pageTransitionVariants}
										initial="enter"
										animate="visible"
										exit="exit"
									>
										<SlideTracker>
											{children}
											<Slide
												trackingKey={FOOTER_SLIDE_TRACKING_KEY}
												layout={SLIDE_LAYOUT_FULL_SCREEN}
												prefferedTheme={THEME_TAUPE}
												style={{
													padding: 0,
												}}
											>
												<Nav />
											</Slide>
										</SlideTracker>
									</motion.div>
								</AnimatePresence>
							</div>
						</motion.div>
					</motion.div>
					<Snackbar />
				</ThemeContext.Provider>
			</GHSourceProvider>
		)
	}
}

PageElement.propTypes = propTypes
PageElement.defaultProps = defaultProps

const WrappedPageElement = withTheme(PageElement)

export default ({ element, props }) => {
	return <WrappedPageElement {...props}>{element}</WrappedPageElement>
}

const Nav = ({ isHeader }) => (
	<div
		css={{
			padding: isHeader ? '10rem 0' : 0,
			[media.mediumUp]: {
				display: 'flex',
				flexDirection: 'column',
				padding: '6rem 0',
				width: '100%',
				minHeight: '100vh',
			},
		}}
	>
		<div
			css={{
				paddingBottom: '5rem',
				[media.large]: {
					flex: 1,
					display: 'flex',
					alignItems: 'center',
					paddingBottom: '6rem',
				},
			}}
		>
			<Menu />
		</div>
		<Footer />
	</div>
)
