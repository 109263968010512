import React, { createContext, useState, useEffect } from 'react'
import queryString from 'query-string'

const GHSourceContext = createContext({
	referralSource: null,
})

export default GHSourceContext

// Helper provider for following gh_src query parameter
// and keeping track of it as the user naviagtes around the site
export const GHSourceProvider = ({ location, children }) => {
	const [source, setSource] = useState(
		// Pull initial gh_src value
		(queryString.parse(location.search) || {}).gh_src
	)

	useEffect(() => {
		// Check for any gh_src changes as the location moves around
		// But keep previous value in state if updated location
		// has falsey value.
		const { gh_src } = queryString.parse(location.search) || {}
		if (!!gh_src) {
			setSource(gh_src)
		}
	}, [location.search])

	return (
		<GHSourceContext.Provider value={source}>
			{children}
		</GHSourceContext.Provider>
	)
}
