/** @jsx jsx */
import { jsx } from '@emotion/core'

import { colors, typeRamp, media } from '../constants'
import { useStaticQuery, graphql } from 'gatsby'

const headerLogoHeight = '3rem'

export default ({ location }) => {
	const data = useStaticQuery(graphql`
		query SiderailtitleQuery {
			site {
				siteMetadata {
					mainNav {
						title
						path
					}
				}
			}
		}
	`)

	const title = data.site.siteMetadata.mainNav.reduce((title, node) => {
		if (location.pathname.startsWith(node.path)) {
			return node.title
		}
		return title
	}, 'Free Association')

	return (
		<div
			css={{
				display: 'none',
				pointerEvents: 'none',
				[media.large]: {
					display: 'block',
					zIndex: 9000,
					position: 'fixed',
					top: 0,
					left: 0,
				},
			}}
		>
			<div
				css={{
					position: 'absolute',
				}}
			>
				<div
					data-section-title
					css={{
						...typeRamp.med_14,
						maxWidth: 'unset', // override max width from ramp
						color: colors.ink,
						width: '100vh',
						height: `${45 / 16}rem`, // match header logo

						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						// Turn this on to see what the hell is going on
						// background: 'blue',
						transform: [
							'rotate(-90deg)', // flip it
							`translateX(calc(-50vh + (${headerLogoHeight} / 2)))`, // bring it to the vertical center
							'translateY(-50vh)', // back it up to the edge of the screen (center of box is on left edge)
							`translateY(calc(${headerLogoHeight} / 2))`, // bring it flush with left edge
							`translateY(2rem)`, // nudge it out to meet the header logo
						].join(' '),
						transition: [].join(', '),
					}}
				>
					{title}
				</div>
			</div>
		</div>
	)
}
