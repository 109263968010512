/** @jsx jsx */
import { jsx } from '@emotion/core'
import { styler, tween, easing } from 'popmotion'
import Two from 'two.js'

import {
	THEME_WHITE,
	THEME_TAUPE,
	THEME_PEACH,
	THEME_CITRON,
	THEME_GREEN,
	THEME_PERIWINKLE,
} from '../constants'
import { withTheme, UIContextPropTypes } from '../context/ThemeContext'
import withLocation from '../containers/withLocation'
import { Component, createRef } from 'react'

const propTypes = {
	...UIContextPropTypes,
}

const defaultProps = {}

const colorMap = {
	[THEME_WHITE]: 'rgba(0, 0, 0, 0)',
	[THEME_TAUPE]: 'rgba(0, 0, 0, 0)',
	[THEME_PEACH]: '#FDE0A9',
	[THEME_CITRON]: '#EBFF92',
	[THEME_GREEN]: '#C0F9D6',
	[THEME_PERIWINKLE]: '#D3E3F6',
}

// Randomly assign a target position that is
// a) between a min/max distance away
// b) on-screen
// Values are in scene percentage (0..1, 0..1)
const generateTargetVector = ({ initialVector, minDistance, maxDistance }) => {
	// NE ----------------- |
	// |                    |
	// |                    |
	// |------------------- SW
	//
	// We want to generate a point that lives within this box.
	// Assign it based on the current screen size,
	const padding = 0.05
	const boundingRect = {
		NW: {
			x: padding,
			y: padding,
		},
		SE: {
			x: 1 - padding,
			y: 1 - padding,
		},
	}

	// Make sure our min is at least the
	// padding distance. Otherwise we may go into an
	// infinite loop where we never find a good value
	let _min = minDistance <= padding ? padding + 0.02 : minDistance

	const distance = Math.random() * (maxDistance - _min) + _min

	const boundingRectContainsPoint = ({ x, y }) => {
		return (
			x >= boundingRect.NW.x &&
			x <= boundingRect.SE.x &&
			y >= boundingRect.NW.y &&
			y <= boundingRect.SE.y
		)
	}

	const target = {
		// Start with a target specifically out of bounds
		x: -1,
		y: -1,
	}

	let i = 0 // Just in case we have a runaway loop :)

	while (!boundingRectContainsPoint(target) && i < 1000) {
		const angle = Math.random() * 360 * (Math.PI / 180)
		target.x = initialVector.x + Math.cos(angle) * distance
		target.y = initialVector.y + Math.sin(angle) * distance
		i++
	}

	return new Two.Vector(target.x, target.y)
}

const randomTargetRotation = ({
	initialRotation,
	maxDistance,
	minDistance,
}) => {
	var directionality = Math.random() >= 0.5 ? 1 : -1

	const distance =
		Math.floor(Math.random() * (maxDistance - minDistance + 1)) + minDistance
	return initialRotation + distance * directionality
}

class BackgroundShapes extends Component {
	constructor(props) {
		super(props)
		this.state = {}
		this.layoutLock = true
		this.renderLock = true
		this.boxRef = createRef()
		this.resizeID = null
		this.scrollID = null
		this.scrollPercentage = 0
	}

	handleResizeScene = () => {
		this.twoInstance.width = document.documentElement.clientWidth
		this.twoInstance.height = document.documentElement.clientHeight
		this.resizeID = null
	}

	handleScroll = () => {
		const { scrollTop, scrollHeight, clientHeight } = document.documentElement
		this.scrollPercentage = scrollTop / (scrollHeight - clientHeight) || 0
		this.scrollID = null
	}

	resizeListener = () => {
		if (!this.resizeID) {
			this.resizeID = window.requestAnimationFrame(this.handleResizeScene)
		}
	}

	scrollListener = () => {
		if (!this.scrollID) {
			this.scrollID = window.requestAnimationFrame(this.handleScroll)
		}
	}

	componentDidMount() {
		const locationURL = new URL(this.props.location.href)
		this.showGuides = !!locationURL.searchParams.get('markers')

		this.handleScroll()
		window.addEventListener('scroll', this.scrollListener, false)

		// Set composite method on the canvas element
		const ctx = this.boxRef.current.getContext('2d')

		ctx.globalCompositeOperation = 'color-burn'

		this.twoInstance = new Two({
			domElement: this.boxRef.current,
			width: document.documentElement.clientWidth,
			height: document.documentElement.clientHeight,
			type: Two.Types.canvas,
			antialias: true,
		})

		window.addEventListener('resize', this.resizeListener, false)

		const compKeys = Object.keys(startingCompositions)
		const firstCompKey = compKeys[Math.floor(Math.random() * compKeys.length)]
		this._data = buildData(startingCompositions[firstCompKey])

		///////////

		this.circle = this.twoInstance.makeEllipse(
			0,
			0,
			0,
			0 // Don't worry about size, it'll get applied in the render loop
		)
		this.circle.noStroke()

		this.circleCenter = this.twoInstance.makeCircle(0, 0, 5)
		this.circleCenter.fill = 'green'
		this.circleCenter.opacity = this.showGuides ? 1 : 0
		this.circleCenter.noStroke()

		this.circleGroup = this.twoInstance.makeGroup(
			this.circle,
			this.circleCenter
		)

		this.circleInitialMark = this.twoInstance.makeCircle(0, 0, 10)
		this.circleInitialMark.opacity = this.showGuides ? 1 : 0
		this.circleInitialMark.fill = 'green'
		this.circleInitialMark.noStroke()

		this.circleTargetMark = this.twoInstance.makeCircle(0, 0, 10)
		this.circleTargetMark.opacity = this.showGuides ? 1 : 0
		this.circleTargetMark.stroke = 'green'
		this.circleTargetMark.linewidth = 3
		this.circleTargetMark.noFill()

		/////

		this.triangle = this.twoInstance.makePath(0, 0, 0, 0, 0, 0)
		this.triangle.vertices = [
			new Two.Anchor(0, 0),
			new Two.Anchor(0, 0),
			new Two.Anchor(0, 0),
			new Two.Anchor(0, 0),
		]
		this.triangle.noStroke()

		this.triangleCenter = this.twoInstance.makeCircle(0, 0, 5)
		this.triangleCenter.fill = 'purple'
		this.triangleCenter.opacity = this.showGuides ? 1 : 0
		this.triangleCenter.noStroke()

		this.triangleGroup = this.twoInstance.makeGroup(
			this.triangle,
			this.triangleCenter
		)

		this.triangleInitialMark = this.twoInstance.makeCircle(0, 0, 10)
		this.triangleInitialMark.opacity = this.showGuides ? 1 : 0
		this.triangleInitialMark.fill = 'purple'
		this.triangleInitialMark.noStroke()

		this.triangleTargetMark = this.twoInstance.makeCircle(0, 0, 10)
		this.triangleTargetMark.opacity = this.showGuides ? 1 : 0
		this.triangleTargetMark.stroke = 'purple'
		this.triangleTargetMark.linewidth = 3
		this.triangleTargetMark.noFill()

		////////

		// Two.js render loop (aims for 60fps)
		this.twoInstance.bind('update', this.updateScene).play()

		this.transitionToNewLayout()
	}

	componentDidUpdate(prevProps) {
		const { theme: prevTheme } = prevProps
		const { theme: newTheme } = this.props
		if (!this.layoutLock && newTheme !== prevTheme) {
			this.layoutLock = true
			this.transitionToNewLayout()
		}
	}

	updateScene = () => {
		this.twoInstance.renderer.ctx.globalCompositeOperation = 'color-burn'

		const { theme } = this.props

		const shapeAlpha = 1

		const maxScreenDim = Math.max(
			this.twoInstance.width,
			this.twoInstance.height
		)

		//////

		// Make the circle diameter the 60% of the larger screen dimension
		const circleSize = maxScreenDim * 0.75

		this.circle.width = circleSize
		this.circle.height = circleSize
		this.circle.fill = colorMap[theme]
		this.circle.opacity = shapeAlpha

		// Apply the interpolated translation
		if (!this.renderLock) {
			this.circleGroup.translation.copy(
				Two.Vector.add(
					this._data.circle.initial.pos,
					Two.Vector.sub(
						this._data.circle.target.pos,
						this._data.circle.initial.pos
					).multiplyScalar(this.scrollPercentage)
				).multiplySelf(this.twoInstance.width, this.twoInstance.height)
			)
		}

		///////

		const triangleSide = maxScreenDim * 0.75
		const triangleHeight = triangleSide * (Math.sqrt(3) / 2)

		// Set triangle size based on screen size
		// Possibly save some memory by setting the xy values
		// instead of instantiating new Anchor objects
		this.triangle.vertices[0].x = 0
		this.triangle.vertices[0].y = -triangleHeight / 2

		this.triangle.vertices[1].x = -triangleSide / 2
		this.triangle.vertices[1].y = triangleHeight / 2

		this.triangle.vertices[2].x = triangleSide / 2
		this.triangle.vertices[2].y = triangleHeight / 2

		this.triangle.vertices[3].x = 0
		this.triangle.vertices[3].y = -triangleHeight / 2

		this.triangle.fill = colorMap[theme]
		this.triangle.opacity = shapeAlpha

		if (!this.renderLock) {
			// Apply the interpolated rotation
			this.triangleGroup.rotation =
				this._data.triangle.initial.rotation +
				this.scrollPercentage *
					(this._data.triangle.target.rotation -
						this._data.triangle.initial.rotation)

			// Apply the interpolated translation
			this.triangleGroup.translation.copy(
				Two.Vector.add(
					this._data.triangle.initial.pos,
					Two.Vector.sub(
						this._data.triangle.target.pos,
						this._data.triangle.initial.pos
					).multiplyScalar(this.scrollPercentage)
				).multiplySelf(this.twoInstance.width, this.twoInstance.height)
			)
		}

		if (this.showGuides && !this.renderLock) {
			this.circleInitialMark.translation
				.copy(this._data.circle.initial.pos)
				.multiplySelf(this.twoInstance.width, this.twoInstance.height)
			this.circleTargetMark.translation
				.copy(this._data.circle.target.pos)
				.multiplySelf(this.twoInstance.width, this.twoInstance.height)

			this.triangleInitialMark.translation
				.copy(this._data.triangle.initial.pos)
				.multiplySelf(this.twoInstance.width, this.twoInstance.height)
			this.triangleTargetMark.translation
				.copy(this._data.triangle.target.pos)
				.multiplySelf(this.twoInstance.width, this.twoInstance.height)
		}
	}

	transitionToNewLayout = () => {
		this.renderLock = true

		// Pick a new layout!
		const compKeys = Object.keys(startingCompositions).filter(
			k => k !== this._data.key
		)
		const nextCompKey = compKeys[Math.floor(Math.random() * compKeys.length)]
		const _new_data = buildData(startingCompositions[nextCompKey])
		const boxStyler = styler(this.boxRef.current)

		tween({
			// 1) Hide the box!
			from: { opacity: 1 },
			to: { opacity: 0 },
			duration: 10,
			ease: easing.easeNone,
		}).start({
			udpate: boxStyler.set,
			complete: () => {
				// 2) Assign the new layout
				// The animation loop is still running... but the user can't see it.
				// We'll assign the updated data, and then effectively "animate" the initial
				// position data. This way we don't have to worry about the current scroll position.
				// The render loop is chugging away and will just read from this._data as we change it here
				this._data = _new_data
				this.renderLock = false

				tween({
					// 3) Show the box
					from: { opacity: 0 },
					to: { opacity: 1 },
					duration: 10,
					ease: easing.easeNone,
				}).start({
					update: boxStyler.set,
					complete: () => {
						// 4) We succesfully transitioned to a new layout! Unlock for the next transition
						this.layoutLock = false
					},
				})
			},
		})
	}

	render() {
		return (
			<canvas
				css={{
					opacity: 0, // start with the canvas hidden, it'll will get shown the first timw transitionToLayout runs
					width: '100vw',
					height: '100vh',
				}}
				ref={this.boxRef}
			/>
		)
	}
}

BackgroundShapes.propTypes = propTypes
BackgroundShapes.defaultProps = defaultProps

export default withTheme(withLocation(BackgroundShapes))

const startingCompositions = {
	A: {
		key: 'A',
		circle: {
			pos: new Two.Vector(0.1, -0.1),
		},
		triangle: {
			pos: new Two.Vector(0.8, 0.4),
			rot: -30.0 * (Math.PI / 180.0),
		},
	},
	B: {
		key: 'B',
		circle: {
			pos: new Two.Vector(0.9, 0.1),
		},
		triangle: {
			pos: new Two.Vector(0.2, 0.5),
			rot: 30.0 * (Math.PI / 180.0),
		},
	},
	C: {
		key: 'C',
		circle: {
			pos: new Two.Vector(0.1, 0.9),
		},
		triangle: {
			pos: new Two.Vector(0.85, 0.4),
			rot: 180.0 * (Math.PI / 180.0),
		},
	},
	D: {
		key: 'D',
		circle: {
			pos: new Two.Vector(0.15, 0.9),
		},
		triangle: {
			pos: new Two.Vector(0.75, 0.3),
			rot: 45.0 * (Math.PI / 180.0),
		},
	},
	E: {
		key: 'E',
		circle: {
			pos: new Two.Vector(0.85, 0.5),
		},
		triangle: {
			pos: new Two.Vector(0.15, 0.9),
			rot: 180.0 * (Math.PI / 180.0),
		},
	},
	F: {
		key: 'F',
		circle: {
			pos: new Two.Vector(0.1, 0.9),
		},
		triangle: {
			pos: new Two.Vector(0.9, 0.4),
			rot: 90.0 * (Math.PI / 180.0),
		},
	},
}

const buildData = ({ key, circle, triangle }) => {
	return {
		key,
		circle: {
			initial: {
				pos: circle.pos,
			},
			target: {
				pos: generateTargetVector({
					initialVector: circle.pos,
					minDistance: 0.06,
					maxDistance: 0.2,
				}),
			},
		},
		triangle: {
			initial: {
				pos: triangle.pos,
				rotation: triangle.rot,
			},
			target: {
				pos: generateTargetVector({
					initialVector: triangle.pos,
					minDistance: 0.06,
					maxDistance: 0.2,
				}),
				rotation: randomTargetRotation({
					initialRotation: triangle.rot,
					maxDistance: 30 * (Math.PI / 180.0),
					minDistance: 10 * (Math.PI / 180.0),
				}),
			},
		},
	}
}
