/** @jsx jsx */
import { jsx } from '@emotion/core'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

import withLocation from '../containers/withLocation'
import { media, LOCATION_STATE_SKIP_SCROLL_RESTORE, colors } from '../constants'
import Container from './Container'
import { Row, Cell } from './Grid'
import LinkPile from './content/LinkPile'

const propTypes = {
	location: PropTypes.object.isRequired,
}

const defaultProps = {}

const Menu = ({ location }) => {
	const { pathname: currentPathname } = location

	return (
		<StaticQuery
			query={graphql`
				query NavMenuQuery {
					site {
						siteMetadata {
							mainNav {
								title
								path
							}
						}
					}
				}
			`}
			render={data => (
				<nav
					data-comp="nav"
					css={{
						position: 'relative',
						width: '100%',
					}}
				>
					<Container>
						<Row>
							<Cell
								cols={{
									[media.small]: 12,
									[media.mediumUp]: 12,
								}}
								offset={{
									[media.small]: 0,
									[media.mediumUp]: 0,
								}}
							>
								<LinkPile
									links={data.site.siteMetadata.mainNav.map(
										({ title, path }) => {
											return {
												title,
												href: path,
												state: {
													// we don't want the scroll restored when navigating away from the nav
													[LOCATION_STATE_SKIP_SCROLL_RESTORE]: true,
												},
												style: currentPathname.startsWith(path)
													? {
															// In active link style
															color: colors.grey,
															'&:hover': {
																color: colors.grey,
																backgroundImage: 'none',
															},
													  }
													: null,
											}
										}
									)}
								/>
							</Cell>
						</Row>
					</Container>
				</nav>
			)}
		/>
	)
}

Menu.propTypes = propTypes
Menu.defaultProps = defaultProps

export default withLocation(Menu)
