import { connect } from 'react-redux'

import { showSnack, dismissSnack } from '../store/actions'
import { getDisplayName } from './utils'

export default WrappedComponent => {
	const mapStateToProps = state => {
		return {
			snackQueue: state.snackbar.queue,
		}
	}

	const mapDispatchToProps = {
		showSnack,
		dismissSnack,
	}

	const C = connect(mapStateToProps, mapDispatchToProps)(WrappedComponent)

	C.displayName = `WithNavData(${getDisplayName(WrappedComponent)})`
	return C
}
