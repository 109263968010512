/** @jsx jsx */
import { jsx } from '@emotion/core'

import { media, typeRamp, colors } from '../constants'
import Container from './Container'
import { buildStandaloneLinkStyle, RouterLink } from './content/Link'
import CopyButton from './CopyButton'

const propTypes = {}

const defaultProps = {}

const emails = [
	{ id: 'hello', email: 'hello@freeassociation.com' },
	{ id: 'press', email: 'press@freeassociation.com' },
]

const inlineFooterLink = [
	buildStandaloneLinkStyle(),
	{
		...typeRamp.light_16,
		border: 'none',
		cursor: 'pointer',
		color: colors.grey,
	},
]

const Footer = () => {
	return (
		<footer
			data-comp="footer"
			css={{
				position: 'relative',
				width: '100%',
			}}
		>
			<Container>
				<div
					css={{
						width: '100%',

						'& > * + *': {
							marginTop: '2.5rem',
						},

						[media.large]: {
							'& > * + *': {
								marginTop: '0',
							},
							display: 'flex',
							justifyContent: 'space-between',
							'& > *': {
								flex: '0 1',
								whiteSpace: 'nowrap',
							},
						},
					}}
				>
					<AddressBlock isFirst={true}>
						<div>Location</div>
						<div>
							We’re a fully remote <br />
							agency based in the U.S.
						</div>
					</AddressBlock>
					<AddressBlock>
						<div>Contacts</div>
						{emails.map(({ id, email }) => {
							return (
								<div key={id}>
									<CopyButton
										aria-label="Copy email address"
										copyValue={email}
										copiedMessage="Copied"
										css={inlineFooterLink}
									>
										{email}
									</CopyButton>
								</div>
							)
						})}
					</AddressBlock>
					<AddressBlock>
						<div>Social</div>
						<div
							css={{
								display: 'flex',

								'& > * ': {
									flex: 0,
								},
								'& > * + * ': {
									marginLeft: '1.5rem',
								},
							}}
						>
							<div>
								<RouterLink
									to="https://www.instagram.com/freeassociation/"
									css={inlineFooterLink}
								>
									Instagram
								</RouterLink>
								<br />
								<RouterLink
									to="https://freeassociation.medium.com/"
									css={inlineFooterLink}
								>
									Medium
								</RouterLink>
							</div>
							<div>
								<RouterLink
									to="https://www.linkedin.com/company/freeassociation/"
									css={inlineFooterLink}
								>
									LinkedIn
								</RouterLink>
								<br />
								<RouterLink
									to="https://twitter.com/FreeAssociation"
									css={inlineFooterLink}
								>
									Twitter
								</RouterLink>
							</div>
						</div>
					</AddressBlock>

					<AddressBlock>
						<div>Legal</div>
						<div>
							<RouterLink to="/terms" css={inlineFooterLink}>
								Terms of Use
							</RouterLink>
						</div>
						<div>
							<RouterLink to="/privacy" css={inlineFooterLink}>
								Privacy Policy
							</RouterLink>
						</div>
					</AddressBlock>
				</div>
			</Container>
		</footer>
	)
}

Footer.propTypes = propTypes
Footer.defaultProps = defaultProps

export default Footer

const AddressBlock = ({ children }) => {
	return (
		<div
			css={{
				...typeRamp.light_16,
				color: colors.grey,
				'& > :first-child': {
					color: colors.ink,
					marginBottom: '0.5rem',
					...typeRamp.med_16,
				},
			}}
		>
			{children}
		</div>
	)
}
