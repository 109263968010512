import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { FOOTER_SLIDE_TRACKING_KEY, THEME_WHITE } from '../constants'
import { withTheme } from '../context/ThemeContext'
import SlideTrackerContext from '../context/SlideTrackerContext'

const propTypes = {
	children: PropTypes.node.isRequired,
}

const defaultProps = {
	children: [],
}

class SlideTracker extends Component {
	constructor(props) {
		super(props)
		this.state = {
			// Which slide is active
			activeSlideKey: '',
		}

		// This lock is used to prevent slide/theme updates from
		// firing when the page content is translated out of view.
		// e.g. when the menu is open
		this.lock = false
		this.lockReleaseTimeout = null

		if (typeof window !== 'undefined' && window.IntersectionObserver) {
			// Watch which slides are in view!
			this.intersectionObserver = new IntersectionObserver(
				this.handleIntersectEvent,
				{
					// Test the intersection every 1% (including zero)
					threshold: Array(100)
						.fill(0)
						.map((_, i) => i / 100 || 0),
					// The intersection test area
					rootMargin: '-25% 0% -20% 0%',
				}
			)
		}

		// Holds data we get on what slides are intersecting
		this.intersectionTracking = {
			// e.g.
			// 'xxxxx': { // Keyed by tracking key
			// 	trackingKey: 'xxxxx', // Index of the slide
			// 	isIntersecting: true, // Is it inside the intersection area?
			// 	ratio: 0.0123, // How much of it is inside the area?
			// 	prefferedTheme: THEME_TAUPE, // What's the theme?
			// }
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.menuOpen) {
			// If the menu is open, make sure we're locked
			// from making updates
			this.lock = true
			clearTimeout(this.lockReleaseTimeout)
		}

		if (!this.props.menuOpen && prevProps.menuOpen) {
			// If the menu has CLOSED, wait a tick to
			// clear the lock while the page animates back into
			// view. We don't want to trigger some incorrect slides
			// during the animation. This isn't super elegant, but I haven't
			// thought of a clean way to merge the route transition and menu operations
			// into the same state machine yet. Obvioulsy there is too much animation
			// going on :) - KT
			clearTimeout(this.lockReleaseTimeout)
			this.lock = true
			this.lockReleaseTimeout = setTimeout(() => {
				this.lock = false
				this.signalSlideInfo()
			}, 500)
		}
	}

	componentWillUnmount() {
		this.intersectionObserver.disconnect()

		// Release the lock so we can get garbage collected
		clearTimeout(this.lockReleaseTimeout)
	}

	registerSlide = ({ target }) => {
		if (this.intersectionObserver) {
			this.intersectionObserver.observe(target)
		}
	}

	deregisterSlide = ({ trackingKey, target }) => {
		if (this.intersectionObserver) {
			this.intersectionObserver.unobserve(target)
		}

		delete this.intersectionTracking[trackingKey]
	}

	handleIntersectEvent = entries => {
		// Mash together latest intersection data with our cache
		this.intersectionTracking = {
			...this.intersectionTracking,
			...entries.reduce((hash, entry) => {
				// Expects the trackingKey to be accessible via data-slide-trackingKey="X"
				const trackingKey = entry.target.getAttribute('data-slide-key')

				// Expects the theme to be accessible via data-slide-index="dark"
				const prefferedTheme = entry.target.getAttribute('data-slide-theme')
				return {
					...hash,
					[trackingKey]: {
						trackingKey,
						isIntersecting: entry.isIntersecting,
						ratio: entry.intersectionRatio,
						prefferedTheme,
					},
				}
			}, {}),
		}

		if (this.lock) {
			// If our lock is on, don't update anything
			// (The update will get triggered upon the unlock timeout)
			return
		}

		this.signalSlideInfo()
	}

	signalSlideInfo = () => {
		if (this.lock) {
			return // If our lock is on, don't signal anything
		}

		// Find the slide that is MOST in view
		const { trackingKey, prefferedTheme } = Object.keys(
			this.intersectionTracking
		).reduce(
			(hash, k) => {
				const { isIntersecting, ratio } = this.intersectionTracking[k]
				if (isIntersecting && ratio > hash.ratio) {
					// Set this slide as the closest thus far
					return this.intersectionTracking[k]
				}
				// On to the next!
				return hash
			},
			{
				trackingKey: 'no-slide',
				isIntersecting: false,
				ratio: 0,
				prefferedTheme: THEME_WHITE,
			}
		)

		if (trackingKey === this.state.activeSlideKey) {
			return // Bail out so we don't render thrash!
		}

		// Signal footer status
		if (trackingKey === FOOTER_SLIDE_TRACKING_KEY) {
			this.props.onEnterFooterNav()
		} else {
			this.props.onExitFooterNav()
		}

		// Assign the theme based on the new active slide
		this.props.setTheme(prefferedTheme)

		// And finally set the active slide
		this.setState({
			activeSlideKey: trackingKey,
		})
	}

	render() {
		const { children } = this.props
		const { activeSlideKey } = this.state

		return (
			<SlideTrackerContext.Provider
				value={{
					intersectionObserver: this.intersectionObserver,
					activeSlideKey,
					registerSlide: this.registerSlide,
					deregisterSlide: this.deregisterSlide,
				}}
			>
				{children}
			</SlideTrackerContext.Provider>
		)
	}
}

SlideTracker.propTypes = propTypes
SlideTracker.defaultProps = defaultProps

export default withTheme(SlideTracker)
