/** @jsx jsx */
import PropTypes from 'prop-types'
import { motion, AnimatePresence } from 'framer-motion'
import { jsx } from '@emotion/core'

import { typeRamp, media, colors, rodoEaseValues } from '../constants'
import withSnacks from '../containers/withSnacks'
import { Component } from 'react'

const propTypes = {
	snackQueue: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			label: PropTypes.string.isRequired,
		})
	),
	dismissSnack: PropTypes.func,
}
const defaultProps = {
	snackQueue: [],
	dismissSnack: () => {},
}

const snackVariants = {
	enter: {
		opacity: 0,
		translateY: 20,
		transition: {
			duration: 0,
		},
	},
	visible: {
		opacity: 1,
		translateY: 0,
		transition: {
			opacity: {
				duration: 0.3,
				ease: 'linear',
			},
			translateY: {
				duration: 0.5,
				ease: rodoEaseValues,
			},
		},
	},
	exit: {
		opacity: 0,
		transition: {
			duration: 0.3,
			ease: 'linear',
		},
	},
}

class Snackbar extends Component {
	constructor(props) {
		super(props)
		this.timeouts = []
		this.state = {
			activeSnack: null,
		}
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		const { snackQueue } = nextProps
		return {
			...prevState,
			activeSnackID: (snackQueue[0] || {}).id,
		}
	}

	componentDidUpdate(_, prevState) {
		if (
			this.state.activeSnackID &&
			this.state.activeSnackID !== prevState.activeSnackID
		) {
			setTimeout(() => {
				this.props.dismissSnack(this.state.activeSnackID)
			}, 3500)
		}
	}

	render() {
		const { snackQueue } = this.props
		return (
			<div
				aria-live="assertive"
				css={{
					pointerEvents: 'none',
					zIndex: 10000,
					position: 'fixed',
					bottom: '0', // place it above the feedback widget button on mobile
					right: '0',
					padding: '1rem',
					width: '100%',
					[media.mediumUp]: {
						padding: '3rem',
					},
				}}
			>
				<div css={{ position: 'relative' }}>
					<AnimatePresence exitBeforeEnter>
						{snackQueue.slice(0, 1).map(item => {
							return (
								<motion.div
									key={item.id}
									variants={snackVariants}
									initial="enter"
									animate="visible"
									exit="exit"
									css={{
										position: 'absolute',
										bottom: 0,
										right: 0,
										width: '100%',
										display: 'flex',
										justifyContent: 'flex-end',
									}}
								>
									<div>
										<div
											css={{
												display: 'inline-block',
												padding: '1rem',
												...typeRamp.med_14,
												color: colors.white,
												background: colors.ink,
											}}
										>
											{item.label}
										</div>
									</div>
								</motion.div>
							)
						})}
					</AnimatePresence>
				</div>
			</div>
		)
	}
}

Snackbar.propTypes = propTypes
Snackbar.defaultProps = defaultProps

export default withSnacks(Snackbar)
