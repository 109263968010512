/** @jsx jsx */
import { FC } from 'react'
import { jsx } from '@emotion/core'

import { media } from '../constants'

type Props = {
	maxWidth?: number | string
	style?: any
	coastToCoastSmall?: boolean
}

const Container: FC<Props> = ({
	maxWidth,
	style,
	children,
	coastToCoastSmall,
}) => {
	const rmaxWidth = maxWidth || '64rem'

	return (
		<div
			data-comp="container"
			css={[
				{
					boxSizing: 'border-box',
					width: '100%',
					maxWidth: `calc(${rmaxWidth} + 2.5rem + 2.5rem)`,
					marginLeft: 'auto',
					marginRight: 'auto',
					paddingLeft: '2.5rem',
					paddingRight: '2.5rem',

					[media.large]: {
						paddingLeft: '4.5rem',
						paddingRight: '4.5rem',
					},

					[media.extraLarge]: {
						paddingLeft: '2.5rem',
						paddingRight: '2.5rem',
					},

					...(coastToCoastSmall
						? {
								[media.small]: {
									paddingLeft: 0,
									paddingRight: 0,
								},
						  }
						: null),
				},
				style,
			]}
		>
			{children}
		</div>
	)
}

export default Container

export const FluidContainer: FC<{
	style?: any
}> = ({ style, children }) => (
	<div
		data-comp="fluid-container"
		css={[
			{
				boxSizing: 'border-box',
				width: '100%',
				marginLeft: 'auto',
				marginRight: 'auto',
				paddingLeft: '2.5rem',
				paddingRight: '2.5rem',
				[media.mediumUp]: {
					paddingLeft: '6.5rem', // Make space for header logo and menu button
					paddingRight: '6.5rem', // Make space for header logo and menu button
				},
			},
			style,
		]}
	>
		{children}
	</div>
)
